/**
 * Traverse any props.children to get their combined text content.
 *
 * This does not add whitespace for readability: `<p>Hello <em>world</em>!</p>`
 * yields `Hello world!` as expected, but `<p>Hello</p><p>world</p>` returns
 * `Helloworld`, just like https://mdn.io/Node/textContent does.
 *
 */
export const textContentFromJSX = (
  elem: string | React.JSX.Element | React.JSX.Element[] | undefined,
): string => {
  if (!elem) {
    return ''
  }
  if (typeof elem === 'string') {
    return elem
  }
  if (elem instanceof Array) {
    return elem.map((item) => textContentFromJSX(item)).join()
  }

  const children = elem.props?.children
  if (children instanceof Array) {
    return children.map(textContentFromJSX).join('')
  }
  return textContentFromJSX(children)
}
