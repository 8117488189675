'use client'

import { useSplitStore } from '@chaseweb/utils/split'

interface InlineFeatureFlagProps {
  flagName: string
  children?: string
}

type InlineFlagParseConfig = {
  inlineText: string
}

export const InlineFeatureFlag = ({
  flagName,
  children = '',
}: InlineFeatureFlagProps) => {
  /* istanbul ignore next */
  const client = useSplitStore((state) => state.clients.user)
  const treatmentValue = JSON.parse(
    client?.getTreatmentWithConfig(flagName).config ?? `{}`,
  ) as InlineFlagParseConfig
  return <> {treatmentValue.inlineText || children} </>
}
