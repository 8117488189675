import type { RefObject } from 'react'
import { create } from 'zustand'

type CtaRef = RefObject<HTMLElement> | null

interface CTAState {
  alwaysVisible: boolean
  setAlwaysVisible: (ref: boolean) => void
  heroCtaRef: CtaRef
  setHeroCtaRef: (ref: CtaRef) => void
  promoCtaRef: CtaRef
  setPromoCtaRef: (ref: CtaRef) => void
}

export const useCTAStore = create<CTAState>((set) => ({
  alwaysVisible: false,
  setAlwaysVisible: (value: boolean) => set(() => ({ alwaysVisible: value })),
  heroCtaRef: null,
  setHeroCtaRef: (heroCtaRef: CtaRef) => set(() => ({ heroCtaRef })),
  promoCtaRef: null,
  setPromoCtaRef: (promoCtaRef: CtaRef) => set(() => ({ promoCtaRef })),
}))
