import type { HeadingType } from '@chaseweb/ui-library/src'
import { Heading, Prose, ResponsiveDataTable } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import type {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  Text,
} from 'html-react-parser'
import parseHtml, { domToReact } from 'html-react-parser'

import { DocumentDetails, PageAnchor, StyledLink } from '@/components'
import type { RelativePathOrHrefExternalType } from '@/types'

import { InlineFeatureFlag } from './components/inline-feature-flag/inline-feature-flag'

export const isElement = (el?: DOMNode): el is Element => el?.nodeType === 1
export const isText = (el?: DOMNode): el is Text => el?.nodeType === 3
const extractText = (
  element: string | React.JSX.Element[] | React.JSX.Element | undefined,
): string => {
  if (typeof element == 'string') return element
  if (Array.isArray(element)) {
    return element
      .map((item: React.JSX.Element | string) =>
        typeof item === 'string' ? item : extractText(item.props.children),
      )
      .join('')
  }
  return ''
}

type ReplaceMapType = {
  replaceh4h6TagsWithSmallGreyText?: boolean
  useH1PageTitle?: boolean
}
export type StringOrElementType =
  | string
  | React.JSX.Element
  | React.JSX.Element[]

export type ParseContentOtionsType = HTMLReactParserOptions & ReplaceMapType

export const parseContent = (
  content: string,
  options?: ParseContentOtionsType,
): StringOrElementType => {
  const parseOptions = {
    replace: options?.replace ?? defaultContentReplace(options),
    trim: options?.trim ?? true,
  }

  const cleanHtmlText = content
    .replaceAll(/>(&nbsp; ?|\s)+?</g, '><')
    .replaceAll(/([^\s|&nbsp;])&nbsp;([^\s|&nbsp;])/g, '$1 $2')
    .replaceAll(/<(p|b|i|tbody)><\/\1>/g, '')
  return parseHtml(cleanHtmlText, parseOptions)
}

export const defaultContentReplace = (
  replaceMap: ReplaceMapType = {},
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { replaceh4h6TagsWithSmallGreyText, useH1PageTitle } = {
    replaceh4h6TagsWithSmallGreyText: false,
    ...replaceMap,
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return function defaultReplace(domNode: DOMNode): JSX.Element | undefined {
    if (isText(domNode)) {
      return domNode.data as unknown as JSX.Element
    }
    if (isElement(domNode)) {
      if (domNode.name === 'a') {
        const children = domNode.children
        const target =
          domNode.attribs.target === '_blank' ? '_blank' : undefined
        const href = domNode.attribs.href as string | undefined
        if (!href) return

        const lastPathSegment = href.split('/').at(-1) as string
        let linkHref = href
        if (
          href.startsWith('/') &&
          !/\.\S{1,4}$/.test(lastPathSegment) &&
          !href.endsWith('/')
        ) {
          linkHref = `${linkHref}/`
        }

        let text = extractText(domToReact(children))
        if (text === 'Download as PDF') {
          return (
            <DocumentDetails
              data={{
                downloadLabel: text,
                versionDetails: 'PDF Version',
                pdfUrl: linkHref,
              }}
            />
          )
        }

        const options = {
          asButton: false,
          mobileOnly: false,
        }
        if (text.includes('{button}')) {
          text = text.replace('{button}', '')
          options.asButton = true
        }
        if (text.includes('{mobile}')) {
          text = text.replace('{mobile}', '')
          options.mobileOnly = true
        }

        return (
          <StyledLink
            variant={options.asButton ? 'primary' : 'link'}
            key={linkHref}
            trackingActionLabel={text}
            sectionLabel="BodyText"
            invertLinkUnderline
            href={linkHref as RelativePathOrHrefExternalType}
            target={target}
            className={cn({
              'md:tw-hidden': options.mobileOnly,
            })}
          >
            {text}
          </StyledLink>
        )
      }

      // This hacky solution and its specific state should only be applied to the components used by the product pages
      // where h4/h6 tags should be displayed as small grey text
      // This has been agreed with the AEM/Content teams
      // https://jira.dynamo.prd.aws.jpmchase.net/browse/DYN-528272
      if (
        replaceh4h6TagsWithSmallGreyText &&
        ['h4', 'h6'].includes(domNode.name)
      ) {
        const children = domNode.children
        return (
          <p className="tw-text-sm tw-text-grey40">
            {/* Need to recursively loop over children as may contain nested links */}
            {Array.from(children).map(defaultReplace)}
          </p>
        )
      }
      if (['h1', 'h2', 'h3', 'h4', 'h5'].includes(domNode.name)) {
        const children = domNode.children

        const id = extractText(domToReact(children))

        return (
          <PageAnchor idString={id} asChild>
            <Heading
              type={domNode.name as HeadingType}
              context={
                useH1PageTitle && domNode.name === 'h1'
                  ? 'pageTitle'
                  : undefined
              }
            >
              {domToReact(children)}
            </Heading>
          </PageAnchor>
        )
      }
      if (['h6'].includes(domNode.name)) {
        throw new Error('h6 is not in the Design System')
      }
      if (['sub'].includes(domNode.name)) {
        const children = domNode.children
        return (
          <p className="!tw-mt-2 tw-text-sm tw-leading-[21px] tw-text-grey40">
            {Array.from(children).map(defaultReplace)}
          </p>
        )
      }
      if (['table'].includes(domNode.name)) {
        const tableData: ParsedResonsiveTable = {
          data: [],
          hasColumnHeader: true,
        }
        let colsCount = 0
        const transformTable = (element: Element) => {
          if (element.children) {
            if (element.name === 'tr') {
              tableData.data.push([])
              if (colsCount === 0 && element.nextSibling) {
                let sibling: Element = element.nextSibling as Element
                while (sibling && sibling.children.length <= 1) {
                  sibling = sibling.nextSibling as Element
                }
                colsCount = sibling?.children.length ?? 0
              }
            }
            if (['td', 'th'].includes(element.name)) {
              const childrenCompiled = domToReact(element['children'], {
                replace: defaultContentReplace(),
              })
              if (+element.attribs.colspan === colsCount) {
                const nodeChildrenList = [...element.children]
                while (nodeChildrenList.length) {
                  const child = nodeChildrenList.pop()
                  if (isElement(child)) {
                    if (
                      child.name === 'b' &&
                      child.next === null &&
                      child.prev === null
                    ) {
                      tableData.data.pop()
                      tableData.title = domToReact(child.children, {
                        replace: defaultContentReplace(),
                      })
                      return
                    }
                    if (child.children) {
                      nodeChildrenList.push(...child.children)
                    }
                  }
                }
                if (!element.parent?.next) {
                  tableData.data.pop()
                  tableData.footer = childrenCompiled
                  return
                }
                tableData.data.pop()
                tableData.description = childrenCompiled
                return
              }

              if (
                tableData.data.length === 1 &&
                tableData.data[0].length === 0 &&
                element.children.length === 0
              ) {
                tableData.hasRowHeader = true
              }

              tableData.data[tableData.data.length - 1].push(childrenCompiled)
              return
            }
            for (const child of element.children) {
              transformTable(child as Element)
            }
          }
        }
        transformTable(domNode)
        const classNameContainerArticle = 'tw-container-article'
        return (
          <Prose className="prose-p:tw-my-4 prose-ul:tw-my-4 prose-table:tw-m-0 prose-table:tw-text-base first:[&_td>*]:tw-mt-0 last:[&_td>*]:tw-mb-0 first:[&_th>*]:tw-mt-0 last:[&_th>*]:tw-mb-0">
            <ResponsiveDataTable
              className={cn({ [classNameContainerArticle]: colsCount < 5 })}
              {...tableData}
              classNameTitle={classNameContainerArticle}
              classNameDescription={classNameContainerArticle}
              classNameFooter={classNameContainerArticle}
            />
          </Prose>
        )
      }
      if (['inline-feature-flag'].includes(domNode.name)) {
        const flagNameAttrib = domNode.attribs['flagName']
        const controlValue = extractText(domToReact(domNode.children))
        return (
          <InlineFeatureFlag flagName={flagNameAttrib}>
            {controlValue}
          </InlineFeatureFlag>
        )
      }
    }
  }
}

type stringOrElement = string | React.JSX.Element | React.JSX.Element[]
export interface ParsedResonsiveTable {
  title?: stringOrElement
  description?: stringOrElement
  data: Array<Array<stringOrElement>>
  footer?: stringOrElement
  hasColumnHeader?: boolean
  hasRowHeader?: boolean
}
