export default function imageLoader({ src, width }) {
  if (src.includes('preview-image')) {
    // when in preview, proxy images via next api.
    // add ?width to stop console warning
    return `${src}&w${width}`
  }
  if (src.includes('_next')) {
    // for staticially imported images or when bypassing the
    // add ?width to stop console warning
    return `${src}?${width}`
  }
  if (src.includes('?bypass-img-cdn')) {
    // for internal use to bypass optimised static images
    // add ?width to stop console warning
    return `${src}?${width}`
  }
  return `${src}/w-${width}.avif`
}
