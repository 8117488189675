import { Breadcrumb } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'

import type { RelativePathType } from '@/types'

import { Link } from '../link'

export const Breadcrumbs = ({
  path,
}: {
  path: { url?: RelativePathType; title: string; className?: string }[]
}) => {
  return (
    <nav aria-label="breadcrumb">
      <Breadcrumb.List className="tw-container">
        {path.map((item, index) => {
          if (path.length === index + 1) {
            return (
              <Breadcrumb.Item key={item.title} asChild>
                <Breadcrumb.Label
                  className={cn('tw-text-sm tw-shadow-[none]', item.className)}
                >
                  {item.title}
                </Breadcrumb.Label>
              </Breadcrumb.Item>
            )
          }
          if (!item.url) throw new Error('url should be present in breadcrumb')
          return (
            <Breadcrumb.Item key={item.title} asChild>
              <Link notTracked href={item.url}>
                <Breadcrumb.Label className={cn(item.className)}>
                  {item.title}
                </Breadcrumb.Label>
              </Link>
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb.List>
    </nav>
  )
}
