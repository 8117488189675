import cassSVG from '@chaseweb/assets/resources/CASS/CASS_logo.svg'
import { cn } from '@chaseweb/utils/cn'

import { LocalImage } from '@/components'

export const CASS_TEXT = 'Current Account Switch Service'

export const CassLogo = ({ className = '' }: { className?: string }) => (
  <LocalImage
    src={cassSVG}
    alt="Current Account Switch Guarantee"
    className={cn('tw-h-14 lg:tw-h-16', className)}
  />
)
