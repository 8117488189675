import { useCallback, useState, type FC } from 'react'
import { MarketingPixelId, MarketingPixelsFragment } from '.'

export const useJobSearchPixelsFragment = ({
  hasAdTargetingConsent,
}: {
  hasAdTargetingConsent: boolean
}) => {
  const [firePixels, setFirePixels] = useState(false)

  const triggerJobSearchEventPixels = useCallback(() => {
    setFirePixels(true)
  }, [])

  const JobSearchPixelsFragment: FC = useCallback(
    () => (
      <>
        {firePixels && (
          <MarketingPixelsFragment
            appendDefaults={false}
            hasAdTargetingConsent={hasAdTargetingConsent}
            marketingPixels={[MarketingPixelId.FACEBOOK_SEARCH_JOBS]}
          />
        )}
      </>
    ),
    [firePixels],
  )

  return {
    JobSearchPixelsFragment,
    triggerJobSearchEventPixels,
  }
}
