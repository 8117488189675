import { Accordion, Heading, Prose } from '@chaseweb/ui-library/src'

import { parseContent } from '@/lib/parse-content'

interface FaqAccordionProps {
  id?: string
  title?: string
  faqList: {
    title: string
    content: string | React.ReactNode
  }[]
  dataTestId?: string
}

export const FaqAccordion = ({
  faqList,
  id,
  title,
  dataTestId = 'FaqAccordion',
}: FaqAccordionProps) => {
  if (!faqList.length) return null
  return (
    <section id={id} data-testid={dataTestId} className="tw-container">
      <Heading type="h2" className="tw-mb-6 tw-mt-18">
        {title}
      </Heading>
      <Accordion.Root type={'multiple'}>
        {faqList.map((faq) => (
          <Accordion.Item value={faq.title} key={faq.title}>
            <Accordion.Trigger headingType="h3">{faq.title}</Accordion.Trigger>
            <Accordion.Content
              className="tw-flex tw-flex-col tw-gap-4"
              data-title={faq.title}
            >
              <Prose className="prose-h4:tw-text-base prose-h4:tw-font-semibold">
                {typeof faq.content === 'string'
                  ? parseContent(faq.content)
                  : faq.content}
              </Prose>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </section>
  )
}
