import bbaBestBritishBankSVG from '@chaseweb/assets/resources/BBA/BBA_Winner_Best_British_bank.svg'
import bbaCurrentAccountSVG from '@chaseweb/assets/resources/BBA/BBA_Winner_Best_current_account_provider.svg'
import bbaBestSavingsProviderSVG from '@chaseweb/assets/resources/BBA/BBA_Winner_Best_savings_provider.svg'

import { LocalImage } from '@/components'

interface BbaLogoProps {
  path?: string
  className?: string
}

export const BbaLogo = ({ path = '', className = '' }: BbaLogoProps) => {
  if (path.endsWith('/product/chase-account/')) {
    return (
      <LocalImage
        src={bbaCurrentAccountSVG}
        alt="British Banking Awards: Best Current Account Provider 2023"
        className={className}
      />
    )
  }
  if (path.endsWith('/product/chase-saver-account/')) {
    return (
      <LocalImage
        src={bbaBestSavingsProviderSVG}
        alt="British Banking Awards: Best Savings Provider 2023"
        className={className}
      />
    )
  }
  return (
    <LocalImage
      src={bbaBestBritishBankSVG}
      alt="British Banking Awards: Best British Bank Winner 2023"
      className={className}
    />
  )
}
