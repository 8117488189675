import {
  AppStoreButton,
  ConsentModalLink,
  DownloadLink,
  Link,
} from '@/components'
import { FeatureActionLinkEnum } from '@/constants'
import type { zActionableLinkType } from '@/lib/validators'

export interface ActionableLinkProps {
  linkData: zActionableLinkType
  sectionLabel?: string
  trackingActionLabel?: string
  children?: string
}

export const ActionableLink = ({
  linkData,
  children,
  sectionLabel,
  trackingActionLabel,
  ...props
}: ActionableLinkProps) => {
  switch (linkData.action) {
    case FeatureActionLinkEnum.DOWNLOAD: {
      return (
        <DownloadLink sectionLabel="CtaItem" {...props}>
          {children}
        </DownloadLink>
      )
    }
    case FeatureActionLinkEnum.MANAGE_COOKIES: {
      return <ConsentModalLink {...props} />
    }
    case FeatureActionLinkEnum.ANDROID_STORE: {
      return <AppStoreButton store="google" {...props} />
    }
    case FeatureActionLinkEnum.APPLE_STORE: {
      return <AppStoreButton store="apple" {...props} />
    }
    default: {
      if (sectionLabel && trackingActionLabel) {
        return (
          <Link
            href={linkData.link}
            sectionLabel={sectionLabel}
            trackingActionLabel={trackingActionLabel}
            {...props}
          >
            {children}
          </Link>
        )
      }
      return (
        <Link notTracked href={linkData.link} {...props}>
          {children}
        </Link>
      )
    }
  }
}
